<template>
    <div class="content">
        <div class="icon" :class="[{mobile: $resize && $mq.below(780)}]">
            <img src="/build/images/not-found.svg">
        </div>
        <div class="title">
             {{$t('not-found.title')}}
        </div>
        <div class="description narrow">
            {{$t('not-found.description')}}
        </div>
        <div class="add-button">
            <router-link v-if="$auth.check() && $auth.user().instance" class="button-tbf-blue" :to="{name: 'dashboard' }">{{$t('not-found.button')}}</router-link>
            <router-link v-else :to="{name: 'login' }" class="button-tbf-blue">{{$t('not-found.button')}}</router-link>
        </div>
    </div>
</template>


<script>

    export default {
        data(){
            return{
            }
        },
        mounted(){
        }
    }
</script>
